import { BehaviorSubjectSubscribe } from 'packages/behavior-subject';
import { useEffect, useState } from 'react';
import { bsGetValue } from 'packages/behavior-subject/fn';

/**
 * Call with a subscriber to a behavior subject to get
 * the values of that subject in your component. Follows normal
 * rules of hooks
 */
export const useBehaviorSubject = <T>(
  subscribe: BehaviorSubjectSubscribe<T>
): T => {
  const [value, setValue] = useState<T>(bsGetValue(subscribe));

  // Subscribe, and unsubscribe when we are finished.
  // We will trigger a duplicate state update on first render, but its ok, we otherwise wont know
  useEffect(
    () =>
      subscribe((value: T) => {
        // Need to use a functional form, else react may be confused if `T` extends Function
        setValue(() => value);
      }),
    [subscribe]
  );
  return value;
};
